import { ThemeOptions } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

import themeStyles from './theme.module.scss';
import './overrides.module.scss';

const themeOptions: ThemeOptions = {
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  direction: 'ltr',
  mixins: {
    toolbar: {
      minHeight: 56,
      '@media (min-width:0px) and (orientation: landscape)': { minHeight: 48 },
      '@media (min-width:600px)': { minHeight: 64 },
    },
  },
  overrides: {},
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    type: 'light',
    primary: {
      main: themeStyles.colorSecondaryGreyDarkest,
      light: themeStyles.colorSecondaryGreyDarkest,
      dark: themeStyles.colorSecondaryGreyDarkest,
      contrastText: '#fff',
    },
    secondary: {
      main: themeStyles.colorHighlightGold,
      light: themeStyles.colorHighlightGold,
      dark: themeStyles.colorHighlightGold,
      contrastText: '#fff',
    },
    error: {
      light: themeStyles.colorError,
      main: themeStyles.colorError,
      dark: themeStyles.colorError,
      contrastText: '#fff',
    },
    grey: {
      '50': '#fafafa',
      '100': '#f5f5f5',
      '200': '#eeeeee',
      '300': '#e0e0e0',
      '400': '#bdbdbd',
      '500': '#9e9e9e',
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: themeStyles.colorTextBase,
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: { paper: '#fff', default: themeStyles.colorSecondaryGreyLightest },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
  },
  props: {},
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  typography: {
    htmlFontSize: parseInt(themeStyles.fontSizeBase),
    fontFamily: themeStyles.fontFamily,
    fontSize: parseInt(themeStyles.fontSizeBase),
    fontWeightLight: parseInt(themeStyles.fontWeightLight),
    fontWeightRegular: parseInt(themeStyles.fontWeightRegular),
    fontWeightMedium: parseInt(themeStyles.fontWeightMedium),
    fontWeightBold: parseInt(themeStyles.fontWeightBold),
    h1: {
      fontFamily: themeStyles.fontFamily,
      fontWeight: parseInt(themeStyles.fontWeightLight),
      fontSize: themeStyles.fontSizeH1,
      lineHeight: 1,
      letterSpacing: themeStyles.headerLetterSpacing,
    },
    h2: {
      fontFamily: themeStyles.fontFamily,
      fontWeight: parseInt(themeStyles.fontWeightLight),
      fontSize: themeStyles.fontSizeH2,
      lineHeight: 1,
      letterSpacing: themeStyles.headerLetterSpacing,
    },
    h3: {
      fontFamily: themeStyles.fontFamily,
      fontWeight: parseInt(themeStyles.fontWeightRegular),
      fontSize: themeStyles.fontSizeH3,
      lineHeight: 1.04,
      letterSpacing: themeStyles.headerLetterSpacing,
    },
    h4: {
      fontFamily: themeStyles.fontFamily,
      fontWeight: parseInt(themeStyles.fontWeightRegular),
      fontSize: themeStyles.fontSizeH4,
      lineHeight: 1.17,
      letterSpacing: '0px',
    },
    h5: {
      fontFamily: themeStyles.fontFamily,
      fontWeight: parseInt(themeStyles.fontWeightRegular),
      fontSize: themeStyles.fontSizeH5,
      lineHeight: 1.33,
      letterSpacing: '0em',
    },
    h6: {
      fontFamily: themeStyles.fontFamily,
      fontWeight: parseInt(themeStyles.fontWeightMedium),
      fontSize: themeStyles.fontSizeH6,
      lineHeight: 1.6,
      letterSpacing: '0px',
    },
    subtitle1: {
      fontFamily: themeStyles.fontFamily,
      fontWeight: parseInt(themeStyles.fontWeightRegular),
      fontSize: themeStyles.fontSizeBase,
      lineHeight: 1.75,
      letterSpacing: '0px',
    },
    subtitle2: {
      fontFamily: themeStyles.fontFamily,
      fontWeight: parseInt(themeStyles.fontWeightMedium),
      fontSize: themeStyles.fontSizeSmall,
      lineHeight: 1.57,
      letterSpacing: '0px',
    },
    body1: {
      fontFamily: themeStyles.fontFamily,
      fontWeight: parseInt(themeStyles.fontWeightRegular),
      fontSize: themeStyles.fontSizeBase,
      lineHeight: 1.5,
      letterSpacing: '0px',
    },
    body2: {
      fontFamily: themeStyles.fontFamily,
      fontWeight: parseInt(themeStyles.fontWeightRegular),
      fontSize: themeStyles.fontSizeBase,
      lineHeight: 1.5,
      letterSpacing: '0px',
    },
    button: {
      fontFamily: themeStyles.fontFamily,
      fontWeight: parseInt(themeStyles.fontWeightMedium),
      fontSize: themeStyles.fontSizeSmall,
      lineHeight: 2,
      letterSpacing: '0px',
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: themeStyles.fontFamily,
      fontWeight: parseInt(themeStyles.fontWeightRegular),
      fontSize: themeStyles.fontSizeSmall,
      lineHeight: 1.66,
      letterSpacing: '0px',
    },
    overline: {
      fontFamily: themeStyles.fontFamily,
      fontWeight: parseInt(themeStyles.fontWeightRegular),
      fontSize: themeStyles.fontSizeSmall,
      lineHeight: 2.66,
      letterSpacing: '0px',
      textTransform: 'uppercase',
    },
  },
  shape: {
    borderRadius: parseInt(themeStyles.borderRadius),
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
};

export const theme = createMuiTheme(themeOptions);
